import React from 'react';
import './App.css';
import logo from './teacher-mandy_logo.png'; // Placeholder for the logo

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#courses">Courses</a></li>
            <li><a href="#testimonials">Testimonials</a></li>
            <li><a href="#contact">Contact Us</a></li>
          </ul>
        </nav>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      
      <section id="home" className="hero-section">
        <h1>Welcome to Teacher Mandy's English Classes</h1>
        <p>Engaging English lessons for children aged 4 to 8.</p>
        <button>Get Started</button>
      </section>
      
      <section id="about" className="about-section">
        <h2>About Us</h2>
        <p>Teacher Mandy has years of experience teaching English to young learners. Our teaching philosophy focuses on engaging and interactive methods to help children learn effectively.</p>
      </section>
      
      <section id="courses" className="courses-section">
        <h2>Our Courses</h2>
        <p>We offer a variety of courses tailored to different age groups and learning styles. Each course is designed to be fun, engaging, and educational.</p>
        <video controls>
          <source src="sample-lesson.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
      
      <section id="testimonials" className="testimonials-section">
        <h2>Testimonials</h2>
        <div className="carousel">
          <p>"Teacher Mandy is wonderful! My child loves her classes."</p>
          <p>"Great experience and improvement in my child's English skills."</p>
          <p>"My child looks forward to every lesson. Highly recommend!"</p>
          {/* <!-- Add more testimonials as needed --> */}
        </div>
      </section>
      
      <section id="contact" className="contact-section">
        <h2>Contact Us</h2>
        <form>
          <label>
            Name:
            <input type="text" name="name" required />
          </label>
          <label>
            Email:
            <input type="email" name="email" required />
          </label>
          <label>
            Phone:
            <input type="tel" name="phone" required />
          </label>
          <label>
            Message:
            <textarea name="message" required></textarea>
          </label>
          <button type="submit">Submit</button>
        </form>
      </section>
      
      <footer>
        <p>&copy; 2024 Teacher Mandy's English Classes</p>
      </footer>
    </div>
  );
}

export default App;